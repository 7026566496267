/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'uEzkq8XBpNCE8T6umEfjBd.png': { uri: '/images/uEzkq8XBpNCE8T6umEfjBd.png' },
'uDvLUWYi7bMD5EeNtmM3d7.png': { uri: '/images/uDvLUWYi7bMD5EeNtmM3d7.png' },
'tknoDoUcyJRuB81wzqKAtE.png': { uri: '/images/tknoDoUcyJRuB81wzqKAtE.png' },
'kfiYeaDR3rYWawz17TY3qb.png': { uri: '/images/kfiYeaDR3rYWawz17TY3qb.png' },
'aC4S3LW6m9pSZcwLpmjY47.png': { uri: '/images/aC4S3LW6m9pSZcwLpmjY47.png' },
'hWyzPZgwBAq6mnzTf4VKyZ.png': { uri: '/images/hWyzPZgwBAq6mnzTf4VKyZ.png' },
'b2XXwDqvFJreP6qoWmEEH3.png': { uri: '/images/b2XXwDqvFJreP6qoWmEEH3.png' },
'3aQzMXDrGaHnFVCzoCmkdV.png': { uri: '/images/3aQzMXDrGaHnFVCzoCmkdV.png' },
'5GXcTHSCTBXhPk8e3ge2R3.png': { uri: '/images/5GXcTHSCTBXhPk8e3ge2R3.png' },
'eXe4yhLvboGCJA33r5WMHm.png': { uri: '/images/eXe4yhLvboGCJA33r5WMHm.png' },
'pSKyuD8W8TUXP2z3M6zUW2.png': { uri: '/images/pSKyuD8W8TUXP2z3M6zUW2.png' },
'f57LQqXW4v51pT191FNHag.png': { uri: '/images/f57LQqXW4v51pT191FNHag.png' },
'9ojBZy1bJT9eB61WDzHcvR.png': { uri: '/images/9ojBZy1bJT9eB61WDzHcvR.png' },
'5kHR3So3vRAvGuDd8XpH44.png': { uri: '/images/5kHR3So3vRAvGuDd8XpH44.png' },
'haksAH6xTwhU1iNCGrCPKV.png': { uri: '/images/haksAH6xTwhU1iNCGrCPKV.png' },
'xis6vUATekoRR9cshUaRhF.png': { uri: '/images/xis6vUATekoRR9cshUaRhF.png' },
'oxWnncwozYVe8o86bJWUya.png': { uri: '/images/oxWnncwozYVe8o86bJWUya.png' },
'j5FCMsKsWyXZs37zPNzbSa.png': { uri: '/images/j5FCMsKsWyXZs37zPNzbSa.png' },
'pq3zfMoVgZm4PQ59v8mjtw.png': { uri: '/images/pq3zfMoVgZm4PQ59v8mjtw.png' },
'jbPdNov3MjVja77tbSDPhH.jpg': { uri: '/images/jbPdNov3MjVja77tbSDPhH.jpg' }
}

export default imageStaticSourcesByFileName
